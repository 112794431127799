<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="no" slot-scope="no, record">
        <router-link
          :to="{ name: 'agent_monthly_bill_subscribe_order_info_commission',
                 params: { agentId: agentId, subscribe_order_id: record.agent_subscribe_order_id},
                 query: { bill_id: billId }}"
        >
          {{ no }}
        </router-link>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showHistoryModal(record.agent_bill_id)">历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 历史模态框 -->
    <show-agent-bill-changed-history
      v-if="isShowHistoryModal"
      :visible.sync="isShowHistoryModal"
      :id="showingHistoryAgentBillId"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findSubscribeBills } from '@/api/agent_monthly_bill'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentSubscribeBillList',
  components: {
    Pagination,
    ShowAgentBillChangedHistory: () => import('@/views/agent_bill_changed_histories/index')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowHistoryModal: false, // 是否显示历史模态框
      showingHistoryAgentBillId: 0, // 正在展示的历史数据agentBillId
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '订单批次',
          width: 150,
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '订单时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '操作人',
          width: 200,
          dataIndex: 'operator_name'
        },
        {
          title: '卡号数量',
          dataIndex: 'cards_count',
          width: 100,
          customRender: formatBigNumber
        },
        {
          title: '订单金额(元)',
          dataIndex: 'total_fee',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '佣金金额(元)',
          dataIndex: 'commission_fee',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showHistoryModal(agentBillId) {
      this.isShowHistoryModal = true
      this.showingHistoryAgentBillId = agentBillId
    },

    fetchData() {
      findSubscribeBills(this.billId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    }
  }
}
</script>

